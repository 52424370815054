import Alpine from 'alpinejs';
import Tooltip from '@ryangjchandler/alpine-tooltip';

Alpine.plugin(
  Tooltip.defaultProps({
    delay: 50,
    theme: 'ci',
  }),
);

window.Alpine = Alpine;

Alpine.data('counter', () => ({
  count: 0,
  fetch() {
    fetch('/api', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query: `
          query CountQuery {
            entryCount(section: "news")
          }
          `,
        variables: {},
      }),
    })
      .then((res) => res.json())
      .then((result) => {
        this.count = result.data.entryCount;
      });
  },
}));

// Load glossar entries in richtext
Alpine.data('glossar', function () {
  return {
    isLoading: true,
    contentText: null,
    glossar: {},
    init() {
      this.contentText = this.$el.innerHTML;
      this.loadGlossarEntries();
    },
    async loadGlossarEntries() {
      try {
        const response = await fetch('/api', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            query: `
                query {
                  entries (section: "glossar") {
                    id
                    title
                    url
                    slug
                    ... on glossar_glossarEntry_Entry {
                      textAdvanced
                    }
                  }
                }
            `,
          }),
        });

        if (response.ok) {
          const data = await response.json();
          this.glossar = data.data.entries;
          this.parseText();
        } else {
          console.error('Fehler beim Abrufen der Glossareinträge.');
        }
      } catch (error) {
        console.error('Fehler beim Abrufen der Glossareinträge:', error);
      }
    },

    parseText() {
      let updatedText = this.contentText;
      const isInsideTooltipSpan = (match, offset) => {
        const precedingText = updatedText.slice(0, offset);
        const openingSpanCount = (precedingText.match(/<span\s+class="tooltip"/g) || []).length;
        const closingSpanCount = (precedingText.match(/<\/span>/g) || []).length;
        return openingSpanCount > closingSpanCount;
      };

      for (const entry of this.glossar) {
        const id = entry.id;
        const key = entry.title;
        const url = entry.slug;
        const maxLength = 140;
        const textAdvanced =
          entry.textAdvanced.length > maxLength
            ? entry.textAdvanced.slice(0, maxLength - 3) + '...'
            : entry.textAdvanced;

        if (textAdvanced) {
          const escapedKey = key.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
          const regex = new RegExp(escapedKey, 'g');
          const encodedUrl = encodeURIComponent(url);
          updatedText = updatedText.replace(regex, (match, offset) => {
            if (isInsideTooltipSpan(match, offset)) {
              return match; // Keine Ersetzung, wenn regex innerhalb eines tooltip-Spans vorkommt
            } else {
              return `
                <span class="tooltip" x-tooltip.interactive.arrowless="{content: \`
                  <strong>${key}</strong>${textAdvanced}<p><a href='/glossar/${encodedUrl}'>mehr</a></p>
                \`, allowHTML: true}">${match}</span>`;
            }
          });
        }
      }
      this.$el.innerHTML = updatedText;
      this.isLoading = false;
    },
  };
});

Alpine.data('randomApp', () => ({
  init() {
    const handleResize = () => {
      const container = document.getElementById("draggableContainer");
      if (container) {
        const dragElements = container.querySelectorAll(".dragable");
        if (dragElements.length > 0) {
          this.randomPositionImproved(container, dragElements);

          dragElements.forEach((element) => {
            this.dragElement(element);
          });
        }
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();
  },
  dragElement(element) {
    let pos1 = 0,
      pos2 = 0,
      pos3 = 0,
      pos4 = 0;

    const dragMouseUp = () => {
      document.onmouseup = null;
      document.onmousemove = null;
      element.classList.remove("drag");

    };

    const dragMouseMove = (event) => {
      event.preventDefault();
      pos1 = pos3 - event.clientX;
      pos2 = pos4 - event.clientY;
      pos3 = event.clientX;
      pos4 = event.clientY;
      element.style.top = `${element.offsetTop - pos2}px`;
      element.style.left = `${element.offsetLeft - pos1}px`;
    };

    const dragMouseDown = (event) => {
      event.preventDefault();
      pos3 = event.clientX;
      pos4 = event.clientY;
      this.bringToFront(element);
      element.classList.add("drag");
      document.onmouseup = dragMouseUp;
      document.onmousemove = dragMouseMove;
    };

    element.onmousedown = dragMouseDown;
  },
  bringToFront(element) {
    const maxZIndex = Math.max(
      ...Array.from(document.querySelectorAll(".dragable"), el => parseInt(window.getComputedStyle(el).zIndex))
    );

    // Erhöhe den z-index des verschobenen Elements
    element.style.zIndex = maxZIndex + 1;
  },
  randomPositionImproved(container, squares) {
    const containerRect = container.getBoundingClientRect();
    const containerWidth = containerRect.width;
    const containerHeight = containerRect.height;
    const minDistance = 220;
    const maxAttempts = 100;

    const getRandomPosition = (size) => {
      const maxX = containerWidth - size;
      const maxY = containerHeight - (size / 1.5);
      const randomX = Math.floor(Math.random() * maxX);
      const randomY = Math.floor(Math.random() * maxY);

      return { x: randomX, y: randomY };
    };

    const isOverlap = (pos, otherPositions) => {
      return otherPositions.some((otherPos) => {
        const distanceX = Math.abs(pos.x - otherPos.x);
        const distanceY = Math.abs(pos.y - otherPos.y);
        return distanceX < minDistance && distanceY < minDistance;
      });
    };

    const positions = [];

    squares.forEach((square) => {
      const squareSize = Math.max(square.clientWidth, square.clientHeight);
      let position;
      let attempts = 0;

      do {
        position = getRandomPosition(squareSize);
        attempts++;
        if (attempts >= maxAttempts) {
          // console.error(`Maximale Anzahl von Versuchen (${maxAttempts}) erreicht.`);
          break;
        }
      } while (isOverlap(position, positions));

      square.style.left = `${position.x}px`;
      square.style.top = `${position.y}px`;
      positions.push(position);
    });
  }
}));

Alpine.start();