import '../css/app.css';
import baguetteBox from 'baguettebox.js';
import { Splide } from '@splidejs/splide';
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';
import Plyr from 'plyr';
import plyrsvg from 'plyr/dist/plyr.svg';
import './alpineComps';

document.addEventListener('DOMContentLoaded', function () {

  // const player = new Plyr('#player', {
  //   loadSprites: false,
  //   iconUrl: plyrsvg
  // });

  const players = Plyr.setup('.js-player', {
    loadSprites: false,
    iconUrl: plyrsvg
  });

  const player = new Plyr(document.querySelector('.js-player'));

  baguetteBox.run('.baguette', {
    noScrollbars: true,
    async: true,
  });

  const slider = document.getElementsByClassName('splide-slider');
  for (let i = 0, len = slider.length; i < len; i++) {
    new Splide(slider[i], {
      perPage: 5,
      perMove: 1,
      gap: 10,
      pagination: true,
      autoplay: false,
      breakpoints: {
        1280: {
          perPage: 4,
        },
        1024: {
          perPage: 3,
        },
        767: {
          perPage: 2,
          arrows: false
        },
        560: {
          perPage: 1,
        },
      },
    }).mount();
  }


  const ticker = document.getElementsByClassName('splide-ticker');
  if (ticker && ticker.length > 0) {
    const splide = new Splide(ticker[0], {
      type: 'loop',
      easing: 'linear',
      autoWidth: true,
      arrows: false,
      pagination: false,
      gap: 20,
      autoScroll: {
        speed: 0.75,
        rewind: false,
        pauseOnHover: true,
      },
    });
    splide.mount({ AutoScroll });
  }
});

